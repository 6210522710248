.Schedule {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
}
.Wrapper {
    margin-left: 75px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: minmax(0, 30%) minmax(0, 70%);
    gap: 20px;
}
