.Register {
    min-width: 100vw;
    min-height: 100vh;
    background: var(--primary);
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 5vh 0;
}
.card {
    background: var(--white);
    border-radius: 5px;
    box-shadow: 2px 2px 10px var(--dark);
    padding: 50px;
}
.card h2 {
    font-size: 22px;
    text-align: center;
    color: var(--dark);
}
.card svg {
    font-size: 45px;
    padding: 20px;
    background: var(--primary);
    color: var(--white);
    width: 70px;
    height: 40px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}
.warning {
    background: var(--red);
    border-radius: 2px;
    margin-bottom: 20px;
}
.warning p {
    color: var(--white);
    margin: 0;
    padding: 10px 25px;
    font-size: 12px;
    position: relative;
}
.warning p::before {
    content: '';
    position: absolute;
    left: 15px;
    top: 14px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid var(--white);
}
.grid {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    gap: 10px;
}
.card form > input {
    width: 100%;
    box-sizing: border-box;
}
.card form input,
.card form select {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    color: var(--grey);
}
.card form select {
    width: 100%;
}
.card form input[type='number']::-webkit-outer-spin-button,
.card form input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.card form input[type='number'] {
    -moz-appearance: textfield;
}
.card form input::placeholder {
    color: var(--grey);
}

.card form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: 200ms;
}
.card form button:hover {
    background: var(--primary);
}
