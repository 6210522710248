.DocProfile {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
}
.Wrapper {
    margin: 0 0 20px 75px;
    display: grid;
    grid-template-columns: minmax(0, 20%) minmax(0, 50%) minmax(0, 30%);
    gap: 10px;
}
.Details > div:nth-child(2) {
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;
}
