.Graph {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
    padding: 20px 20px 0 75px;
}
.Wrapper {
    display: grid;
    grid-template-columns: minmax(0, 30%) minmax(0, 70%);
    margin-top: 40px;
}
