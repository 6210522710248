.Schedule {
    margin: 20px;
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
}

.Schedule > h4 {
    margin: 0 20px;
    padding: 20px 0 0 0;
    color: var(--primary);
    text-align: center;
    font-weight: 500;
}
.Schedule ul {
    list-style-type: none;
    padding: 10px 20px 20px 20px;
    margin: 0;
}
.Schedule ul li {
    font-size: 12px;
    position: relative;
    margin: 5px 0;
    border-bottom: 1px solid var(--light);
    padding: 10px 0;
    color: var(--dark-blue);
}
.Schedule ul li span {
    position: absolute;
    right: 0;
}
.Schedule ul li:nth-child(1) {
    font-weight: bold;
    padding: 10px 10px;
}
.Schedule ul li:nth-child(1) span {
    right: 20px;
}
.Schedule ul li:last-child {
    border: none;
}
