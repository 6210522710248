.SideNav {
    position: fixed;
    height: 94vh;
    width: 50px;
    left: 0;
    top: 20px;
    background: var(--white);
    border-radius: 0 4px 4px 0;
    box-shadow: 0 5px 10px var(--grey);
    z-index: 100;
    transition: 200ms;
}
.SideNav:hover {
    width: 200px;
}
.Wrapper {
    margin-top: 0;
}
.Wrapper p:first-child {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    height: 100%;
    padding: 10px;
    margin: 0;
    color: var(--white);
    background: var(--primary);
    text-align: center;
    position: relative;
    border-radius: 0 4px 0 0;
    cursor: pointer;
}
.Wrapper p:first-child:hover {
    color: var(--white);
    background: var(--primary);
}

.Wrapper a {
    display: block;
    padding: 10px;
    position: relative;
    text-decoration: none;
}
.Wrapper a svg {
    display: inline-block;
    color: var(--grey);
    margin: 0 auto;
    font-size: 20px;
}

.Wrapper a:hover {
    background: var(--light);
}
.Wrapper a:hover > svg {
    color: var(--primary);
}
.Wrapper a.active {
    background: var(--light);
}
.Wrapper a.active > svg {
    color: var(--primary);
}

.Wrapper a span {
    text-decoration: none;
    font-size: 10px;
    color: var(--white);
    background: var(--grey);
    position: absolute;
    top: 10px;
    left: 55px;
    padding: 2px 15px;
    border-radius: 2px;
    display: none;
    transition: 300ms;
    opacity: 0.9;
}
.Wrapper a span::before {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--grey);
    transform: rotate(45deg);
    position: absolute;
    left: -3px;
    top: 6px;
}
.Wrapper a:hover > span {
    display: block;
}
