.Professional {
    padding: 0 20px 20px 50px;
}
.Professional h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}

.successFlash {
    color: var(--white);
    background: var(--green);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.warningFlash {
    color: var(--white);
    background: var(--red);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.wrapInp {
    display: grid;
    grid-template-columns: minmax(0, 60%) minmax(0, 40%);
    gap: 10px;
}
.wrapInp input,
.wrapInp textarea {
    width: 100%;
}

.Professional form input {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Professional form input::placeholder {
    color: var(--grey2);
}
.Professional form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Professional form button:hover {
    background: var(--primary);
}
