.Wrapper {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
    padding: 20px 20px 20px 75px;
}

.Settings {
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
    display: grid;
    grid-template-columns: minmax(0, 25%) minmax(0, 75%);
}
