.Speciality {
    padding: 0 20px 20px 50px;
}

.Speciality h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}

.wrapInp {
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 5px;
    width: 50%;
}
.Speciality form input {
    display: block;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Speciality form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Speciality form button:hover {
    background: var(--primary);
}

.RegisteredSpeciality {
    margin-top: 30px;
    width: 50%;
}
.RegisteredSpeciality h5 {
    margin: 0 0 5px 0;
    font-weight: 400;
    color: var(--primary);
}
.RegisteredSpeciality p {
    display: inline;
    font-size: 11px;
    background: var(--light-grey);
    color: var(--grey);
    border-radius: 2px;
    padding: 2px 4px;
    margin-right: 4px;
}
.RegisteredSpeciality p span {
    margin: 0 5px;
    color: var(--dark-blue);
    cursor: pointer;
}
