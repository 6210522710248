@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap);
.MultiLine_MultiLine__1X-pj {
    width: 95%;
    margin: 0 auto;
}

.PieChart_pieChart__FUIls {
    width: 95%;
    margin: 0 auto;
}

.SideNav_SideNav__1BdMg {
    position: fixed;
    height: 94vh;
    width: 50px;
    left: 0;
    top: 20px;
    background: var(--white);
    border-radius: 0 4px 4px 0;
    box-shadow: 0 5px 10px var(--grey);
    z-index: 100;
    transition: 200ms;
}
.SideNav_SideNav__1BdMg:hover {
    width: 200px;
}
.SideNav_Wrapper__w2nQL {
    margin-top: 0;
}
.SideNav_Wrapper__w2nQL p:first-child {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    height: 100%;
    padding: 10px;
    margin: 0;
    color: var(--white);
    background: var(--primary);
    text-align: center;
    position: relative;
    border-radius: 0 4px 0 0;
    cursor: pointer;
}
.SideNav_Wrapper__w2nQL p:first-child:hover {
    color: var(--white);
    background: var(--primary);
}

.SideNav_Wrapper__w2nQL a {
    display: block;
    padding: 10px;
    position: relative;
    text-decoration: none;
}
.SideNav_Wrapper__w2nQL a svg {
    display: inline-block;
    color: var(--grey);
    margin: 0 auto;
    font-size: 20px;
}

.SideNav_Wrapper__w2nQL a:hover {
    background: var(--light);
}
.SideNav_Wrapper__w2nQL a:hover > svg {
    color: var(--primary);
}
.SideNav_Wrapper__w2nQL a.SideNav_active__AOd7S {
    background: var(--light);
}
.SideNav_Wrapper__w2nQL a.SideNav_active__AOd7S > svg {
    color: var(--primary);
}

.SideNav_Wrapper__w2nQL a span {
    text-decoration: none;
    font-size: 10px;
    color: var(--white);
    background: var(--grey);
    position: absolute;
    top: 10px;
    left: 55px;
    padding: 2px 15px;
    border-radius: 2px;
    display: none;
    transition: 300ms;
    opacity: 0.9;
}
.SideNav_Wrapper__w2nQL a span::before {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--grey);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    left: -3px;
    top: 6px;
}
.SideNav_Wrapper__w2nQL a:hover > span {
    display: block;
}

.Summery_Summery__3dl82 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 33%));
    grid-gap: 20px;
    gap: 20px;
}
.Summery_Summery__3dl82 > div {
    border-radius: 5px;
    padding: 20px 0 30px 20px;
    color: var(--white);
    opacity: 0.8;
    position: relative;
}
.Summery_Summery__3dl82 > div:nth-child(1) {
    background: var(--primary);
}
.Summery_Summery__3dl82 > div:nth-child(2) {
    background: var(--green);
}
.Summery_Summery__3dl82 > div:nth-child(3) {
    background: var(--red);
}
.Summery_Summery__3dl82 > div > h3 {
    font-size: 28px;
    margin: 0;
}
.Summery_Summery__3dl82 > div > p {
    margin: 0;
}
.Summery_Summery__3dl82 > div > svg {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 70px;
    opacity: 0.7;
}

.Graph_Graph__sgfkm {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
    padding: 20px 20px 0 75px;
}
.Graph_Wrapper__2i0RT {
    display: grid;
    grid-template-columns: minmax(0, 30%) minmax(0, 70%);
    margin-top: 40px;
}

.Doctors_Doctors__1Ue1K {
    margin: 20px 0 20px 75px;
    background: var(--white);
    padding: 0 0 10px 0;
    display: block;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 5px 10px var(--light-grey);
}

.SingleDoctor_SingleDoctor__8zTs1 {
    padding: 10px 0 0 10px;
    overflow: hidden;
}
.SingleDoctor_SingleDoctor__8zTs1 > p {
    margin: 4px 0;
}

.SingleDoctor_SingleDoctor__8zTs1 p:nth-child(2) {
    font-size: 14px;
    margin-top: 15px;
    color: var(--primary);
    font-weight: 500;
}
.SingleDoctor_SingleDoctor__8zTs1 p:nth-child(3) {
    font-size: 12px;
    margin-top: 4px;
    color: var(--dark-blue);
    font-weight: 500;
}

.SingleDoctor_SingleDoctor__8zTs1 p:nth-child(4) {
    font-size: 12px;
    margin-top: 4px;
    color: var(--grey);
    font-weight: 500;
}

.Forum_Forum__3tkNK {
    background: var(--white);
    padding: 5px;
    box-sizing: border-box;
    margin: 20px 20px 20px 0;
}
.Forum_Forum__3tkNK h2 {
    color: var(--primary);
    margin: 5px 25px;
    font-weight: 400;
    font-size: 24px;
}
.Forum_Forum__3tkNK > div {
    margin: 20px 20px;
    border-bottom: 2px solid var(--light);
    transition: 200ms;
}
.Forum_Forum__3tkNK > div:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    cursor: pointer;
}
.Forum_Forum__3tkNK > div > h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px;
    display: inline;
}
.Forum_Forum__3tkNK > div > p:nth-child(2) {
    display: inline;
    font-size: 10px;
    margin: 0 10px;
    color: var(--dark-grey);
}
.Forum_Forum__3tkNK > div > p:nth-child(3) {
    font-size: 12px;
    font-weight: 500;
    margin: 5px;
    color: var(--secondary);
}
.Forum_Forum__3tkNK > div > p:nth-child(4) {
    font-size: 12px;
    margin: 5px;
    color: var(--deep-blue);
}

.PatientList_PatientList__3hZMO {
    margin: 20px 0 20px 75px;
}
.PatientList_PatientSearch__39DF1 input {
    width: 100%;
    outline: 0;
    border: none;
    padding: 10px;
    color: var(--grey);
    box-shadow: 0 5px 10px var(--light-grey);
    box-sizing: border-box;
}
.PatientList_PatientSearch__39DF1 input:focus,
.PatientList_PatientSearch__39DF1 input:hover {
    outline: 0;
}
.PatientList_PatientSearch__39DF1 input::-webkit-input-placeholder {
    color: var(--grey);
}
.PatientList_PatientSearch__39DF1 input:-ms-input-placeholder {
    color: var(--grey);
}
.PatientList_PatientSearch__39DF1 input::placeholder {
    color: var(--grey);
}

.PatientList_heading__1Jz_C {
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    background: var(--white);
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
}

.PatientList_AllPatient__mEmT4 {
    background: var(--white);
    padding: 0 0 10px 0;
    display: block;
    width: 100%;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 5px 10px var(--light-grey);
}

.SinglePatient_SinglePatient__l7Ss2 {
    padding: 10px 0 0 10px;
    overflow: hidden;
}
.SinglePatient_SinglePatient__l7Ss2 > p {
    margin: 4px 0;
}
.SinglePatient_SinglePatient__l7Ss2 > p:nth-child(2) {
    font-size: 12px;
    color: var(--dark-blue);
}
.SinglePatient_SinglePatient__l7Ss2 > p:nth-child(3) {
    font-size: 10px;
    color: var(--grey);
}
.SinglePatient_SinglePatient__l7Ss2 > p:nth-child(3) > span:nth-child(2),
.SinglePatient_SinglePatient__l7Ss2 > p:nth-child(3) > span:nth-child(3) {
    margin-left: 20px;
}

.Dashboard_Wrapper__2L-9Q {
    background: var(--light);
    max-width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    grid-gap: 10px;
    gap: 10px;
}

.Login_Login__1ujut {
    width: 100vw;
    height: 100vh;
    background: var(--primary);
}
.Login_wrapper__1IflB {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 15vh;
}
.Login_card__2ebUg {
    background: var(--white);
    border-radius: 5px;
    box-shadow: 2px 2px 10px var(--dark);
    padding: 50px;
}
.Login_card__2ebUg h2 {
    font-size: 22px;
    text-align: center;
    color: var(--dark);
}
.Login_card__2ebUg svg {
    font-size: 45px;
    padding: 20px;
    background: var(--primary);
    color: var(--white);
    width: 70px;
    height: 40px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}
.Login_card__2ebUg form input {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    color: var(--grey);
}
.Login_card__2ebUg form input::-webkit-input-placeholder {
    color: var(--grey2);
}
.Login_card__2ebUg form input:-ms-input-placeholder {
    color: var(--grey2);
}
.Login_card__2ebUg form input::placeholder {
    color: var(--grey2);
}
.Login_card__2ebUg form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: 200ms;
}
.Login_card__2ebUg form button:hover {
    background: var(--primary);
}
.Login_card__2ebUg > p {
    font-size: 12px;
    text-align: center;
}
.Login_card__2ebUg > p > a {
    color: var(--primary);
    text-decoration: none;
}

.ViewSchedule_Wrapper__11DRa {
    background: var(--white);
    margin-right: 20px;
    border-radius: 4px;
    overflow: auto;
    padding: 20px;
    box-shadow: 0 5px 10px var(--light-grey);
}
.ViewSchedule_ViewSchedule__1G9o7 > div > h4 {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary);
    margin: 0 0 10px 0;
    text-align: center;
}
.ViewSchedule_calender__3ebQd {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 14.28%));
}
/* .calender > div {
    border-right: 1px solid var(--light-grey);
}
.calender > div:nth-child(7) {
    border: none;
} */
.ViewSchedule_calender__3ebQd > div > p:nth-child(1) {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--primary);
    padding: 5px 0;
}
.ViewSchedule_calender__3ebQd .ViewSchedule_time__3ikeE {
    text-align: center;
    display: block;
    padding: 5px 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--white);
    background: var(--primary);
    margin: 10px 5px;
    box-shadow: 0 5px 10px var(--light-grey);
    border-radius: 2px;
}
.ViewSchedule_calender__3ebQd .ViewSchedule_time__3ikeE span {
    display: block;
}
.ViewSchedule_time__3ikeE {
    position: relative;
    overflow: hidden;
}
.ViewSchedule_time__3ikeE span:nth-child(2) {
    position: absolute;
    top: -27px;
    right: -27px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    background: var(--white);
    border-radius: 50px;
    color: var(--dark-blue);
    cursor: pointer;
    transition: 200ms;
}
.ViewSchedule_time__3ikeE:hover span:nth-child(2) {
    top: -7px;
    right: -7px;
}

.AllChambers_AllChambers__ZyX2q {
    margin: 20px;
    background: var(--white);
    padding: 10px 0;
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
}
.AllChambers_AllChambers__ZyX2q h4 {
    margin: 0 20px;
    padding: 20px 0 0 0;
    color: var(--primary);
    text-align: center;
    font-weight: 500;
}
.AllChambers_SingleChamber__xh0Nb {
    margin: 20px;
}

.AllChambers_SingleChamber__xh0Nb p {
    margin: 5px;
    font-size: 12px;
}
.AllChambers_SingleChamber__xh0Nb p:nth-child(1) {
    color: var(--dark-blue);
}
.AllChambers_SingleChamber__xh0Nb p:nth-child(2) {
    color: var(--grey);
}

.Card_Card__3TfPv {
    margin: 20px 0 0 0;
    padding: 30px 0;
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
}
.Card_DocImg__2ActT {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid var(--secondary);
    margin: 0 auto;
}
.Card_title__2Dd0g {
    text-align: center;
}
.Card_title__2Dd0g h3 {
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0;
    color: var(--primary);
}
.Card_title__2Dd0g p {
    margin: 5px 0;
    font-size: 14px;
}
.Card_title__2Dd0g p:nth-child(2) {
    color: var(--dark-blue);
}
.Card_title__2Dd0g p:nth-child(3) {
    color: var(--grey);
}
.Card_title__2Dd0g > span {
    background: var(--light-grey);
    width: 80%;
    height: 1px;
    display: block;
    margin: 15px auto;
}
.Card_Chamber__1gIuE p:nth-child(1) {
    text-align: center;
    color: var(--dark-blue);
    margin: 5px 0;
}
.Card_Chamber__1gIuE p:nth-child(2) {
    color: var(--grey);
    font-size: 12px;
    margin: 5px 30px;
    text-align: center;
}

.Card_BMDC__266QV {
    background: var(--white);
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0 5px 10px var(--light-grey);
}
.Card_BMDC__266QV p {
    margin: 5px 0;
    text-align: center;
}
.Card_BMDC__266QV p:nth-child(1) {
    font-size: 14px;
    color: var(--dark-blue);
    font-weight: 400;
}
.Card_BMDC__266QV p:nth-child(2) {
    font-weight: 600;
    color: var(--dark-blue);
}

.DocProfile_DocProfile__3ZoFx {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
}
.DocProfile_Wrapper__yk0qQ {
    margin: 0 0 20px 75px;
    display: grid;
    grid-template-columns: minmax(0, 20%) minmax(0, 50%) minmax(0, 30%);
    grid-gap: 10px;
    gap: 10px;
}
.DocProfile_Details__Kj9KX > div:nth-child(2) {
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;
}

.Professional_Professional__3w7aD {
    background: var(--white);
    border-radius: 4px;
    padding: 20px;
    margin: 20px 0 0 20px;
    box-shadow: 0 5px 10px var(--light-grey);
}

.Professional_Professional__3w7aD h4 {
    margin: 5px 0;
}
.Professional_SingleProfession__1vAhd p {
    font-size: 12px;
    margin: 5px 0;
}

.Schedule_Schedule__2ty8- {
    margin: 20px;
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
}

.Schedule_Schedule__2ty8- > h4 {
    margin: 0 20px;
    padding: 20px 0 0 0;
    color: var(--primary);
    text-align: center;
    font-weight: 500;
}
.Schedule_Schedule__2ty8- ul {
    list-style-type: none;
    padding: 10px 20px 20px 20px;
    margin: 0;
}
.Schedule_Schedule__2ty8- ul li {
    font-size: 12px;
    position: relative;
    margin: 5px 0;
    border-bottom: 1px solid var(--light);
    padding: 10px 0;
    color: var(--dark-blue);
}
.Schedule_Schedule__2ty8- ul li span {
    position: absolute;
    right: 0;
}
.Schedule_Schedule__2ty8- ul li:nth-child(1) {
    font-weight: bold;
    padding: 10px 10px;
}
.Schedule_Schedule__2ty8- ul li:nth-child(1) span {
    right: 20px;
}
.Schedule_Schedule__2ty8- ul li:last-child {
    border: none;
}

.Register_Register__28yeG {
    min-width: 100vw;
    min-height: 100vh;
    background: var(--primary);
}
.Register_wrapper__1wFxq {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 5vh 0;
}
.Register_card__2RM-3 {
    background: var(--white);
    border-radius: 5px;
    box-shadow: 2px 2px 10px var(--dark);
    padding: 50px;
}
.Register_card__2RM-3 h2 {
    font-size: 22px;
    text-align: center;
    color: var(--dark);
}
.Register_card__2RM-3 svg {
    font-size: 45px;
    padding: 20px;
    background: var(--primary);
    color: var(--white);
    width: 70px;
    height: 40px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}
.Register_warning__3piv9 {
    background: var(--red);
    border-radius: 2px;
    margin-bottom: 20px;
}
.Register_warning__3piv9 p {
    color: var(--white);
    margin: 0;
    padding: 10px 25px;
    font-size: 12px;
    position: relative;
}
.Register_warning__3piv9 p::before {
    content: '';
    position: absolute;
    left: 15px;
    top: 14px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid var(--white);
}
.Register_grid__KTz3a {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    grid-gap: 10px;
    gap: 10px;
}
.Register_card__2RM-3 form > input {
    width: 100%;
    box-sizing: border-box;
}
.Register_card__2RM-3 form input,
.Register_card__2RM-3 form select {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    color: var(--grey);
}
.Register_card__2RM-3 form select {
    width: 100%;
}
.Register_card__2RM-3 form input[type='number']::-webkit-outer-spin-button,
.Register_card__2RM-3 form input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.Register_card__2RM-3 form input[type='number'] {
    -moz-appearance: textfield;
}
.Register_card__2RM-3 form input::-webkit-input-placeholder {
    color: var(--grey);
}
.Register_card__2RM-3 form input:-ms-input-placeholder {
    color: var(--grey);
}
.Register_card__2RM-3 form input::placeholder {
    color: var(--grey);
}

.Register_card__2RM-3 form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: 200ms;
}
.Register_card__2RM-3 form button:hover {
    background: var(--primary);
}

.Schedule_Schedule__1EPGh {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
}
.Schedule_Wrapper__2dUNY {
    margin-left: 75px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: minmax(0, 30%) minmax(0, 70%);
    grid-gap: 20px;
    gap: 20px;
}

.SetSchedule_SetSchedule__2AEUa {
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
    padding: 20px;
    box-sizing: border-box;
}
.SetSchedule_SetSchedule__2AEUa h4 {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary);
    margin: 0 0 10px 0;
    text-align: center;
}
.SetSchedule_SetSchedule__2AEUa h5 {
    font-size: 16px;
    font-weight: 500;
    color: var(--white);
    background: var(--primary);
    margin: 10px 0;
    padding: 0 20px 0 10px;
    display: inline-block;
    border-radius: 4px 0 0 4px;
    position: relative;
}
.SetSchedule_SetSchedule__2AEUa h5::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 13px solid var(--primary);
    border-right: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 11px solid var(--primary);
    right: -23px;
    top: 0;
}

.SetSchedule_checkGrid__1BIl6 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 4fr));
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 20px;
}
.SetSchedule_singleCheck__1E3D0 input[type='checkbox'] {
    display: none;
}

.SetSchedule_singleCheck__1E3D0 label {
    display: block;
    width: 100%;
    color: var(--primary);
    background: var(--light-grey);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px 15px;
    font-size: 12px;
    color: var(--grey);
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 200ms;
}

.SetSchedule_singleCheck__1E3D0 input[type='checkbox']:checked + label {
    color: var(--white);
    background: var(--primary);
}
.SetSchedule_singleCheck__1E3D0 label:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

/* 
.selectWrap {
    position: relative;
}
.SetSchedule > .selectWrap > select {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    color: var(--dark-blue);
    background: var(--white);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--primary);
    outline: 0;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
}
.SetSchedule > .selectWrap > select > option {
    color: var(--primary);
    background: var(--white);
    cursor: pointer;
}
.SetSchedule > .selectWrap span {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid var(--dark-blue);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    right: 12px;
    top: 12px;
} */

.SetSchedule_inp__1CYb8 {
    display: grid;
    grid-template-columns: minmax(0, 30%) minmax(0, 30%) minmax(0, 40%);
    grid-gap: 10px;
    gap: 10px;
}

.SetSchedule_inp__1CYb8 input[type='number'] {
    -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.SetSchedule_inp__1CYb8 input,
.SetSchedule_inp__1CYb8 select {
    border: 1px solid var(--primary);
    color: var(--grey);
    padding: 5px;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: var(--white);
}
.SetSchedule_inp__1CYb8 input:hover,
.SetSchedule_inp__1CYb8 input:focus,
.SetSchedule_inp__1CYb8 select:focus {
    outline: 0;
}

.SetSchedule_SetSchedule__2AEUa button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    border: 1px solid var(--primary);
    background: var(--primary);
    color: var(--white);
    margin-top: 10px;
    padding: 5px;
    border-radius: 2px;
    display: block;
    width: 100%;
    cursor: pointer;
}

.Chamber_Chamber__QcMRj {
    padding: 0 20px 20px 50px;
}
.Chamber_Chamber__QcMRj h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}

.Chamber_wrapInp__2JGGm {
    width: 60%;
}
.Chamber_wrapInp__2JGGm input,
.Chamber_wrapInp__2JGGm textarea {
    width: 100%;
}

.Chamber_Chamber__QcMRj input,
.Chamber_Chamber__QcMRj select {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Chamber_Chamber__QcMRj select {
    width: 50%;
}
.Chamber_Chamber__QcMRj input::-webkit-input-placeholder {
    color: var(--grey2);
}
.Chamber_Chamber__QcMRj input:-ms-input-placeholder {
    color: var(--grey2);
}
.Chamber_Chamber__QcMRj input::placeholder {
    color: var(--grey2);
}
.Chamber_Chamber__QcMRj button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Chamber_Chamber__QcMRj button:hover {
    background: var(--primary);
}

.Chamber_Chamber__QcMRj textarea {
    border: none;
    border-radius: 4px;
    background: var(--light-grey);
    color: var(--grey);
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 20px;
}
.Chamber_Chamber__QcMRj textarea:hover,
.Chamber_Chamber__QcMRj textarea:focus {
    color: var(--grey);
    outline: 0;
}
.Chamber_Chamber__QcMRj textarea::-webkit-input-placeholder {
    color: var(--grey2);
}
.Chamber_Chamber__QcMRj textarea:-ms-input-placeholder {
    color: var(--grey2);
}
.Chamber_Chamber__QcMRj textarea::placeholder {
    color: var(--grey2);
}

.General_General__3r0n6 {
    padding: 0 20px 20px 50px;
}
.General_General__3r0n6 h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}
.General_successFlash__zhqgD {
    color: var(--white);
    background: var(--green);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.General_warningFlash__1ks05 {
    color: var(--white);
    background: var(--red);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.General_wrapInp__16TqK {
    display: grid;
    grid-template-columns: minmax(0, 60%) minmax(0, 40%);
    grid-gap: 10px;
    gap: 10px;
}
.General_wrapInp__16TqK input,
.General_wrapInp__16TqK textarea {
    width: 100%;
}

.General_General__3r0n6 form input,
.General_General__3r0n6 form select {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.General_General__3r0n6 form select {
    width: 50%;
}
.General_General__3r0n6 form input::-webkit-input-placeholder {
    color: var(--grey2);
}
.General_General__3r0n6 form input:-ms-input-placeholder {
    color: var(--grey2);
}
.General_General__3r0n6 form input::placeholder {
    color: var(--grey2);
}
.General_General__3r0n6 form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.General_General__3r0n6 form button:hover {
    background: var(--primary);
}

.Password_Password__213su {
    padding: 0 20px 20px 50px;
}
.Password_Password__213su h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}
.Password_successFlash__2UMQ6 {
    color: var(--white);
    background: var(--green);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.Password_warningFlash__3DkwU {
    color: var(--white);
    background: var(--red);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}

.Password_wrapInp__25eCX {
    display: grid;
    grid-template-columns: minmax(0, 60%) minmax(0, 40%);
    grid-gap: 10px;
    gap: 10px;
}
.Password_wrapInp__25eCX input,
.Password_wrapInp__25eCX textarea {
    width: 100%;
}

.Password_Password__213su form input {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Password_Password__213su form input::-webkit-input-placeholder {
    color: var(--grey2);
}
.Password_Password__213su form input:-ms-input-placeholder {
    color: var(--grey2);
}
.Password_Password__213su form input::placeholder {
    color: var(--grey2);
}
.Password_Password__213su form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Password_Password__213su form button:hover {
    background: var(--primary);
}

.Prescription_Prescription__2hVzX {
    padding: 0 20px 20px 50px;
}
.Prescription_Prescription__2hVzX h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}

.Prescription_successFlash__3suj0 {
    color: var(--white);
    background: var(--green);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.Prescription_warningFlash__BozJa {
    color: var(--white);
    background: var(--red);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}

.Prescription_wrapInp__2X0D8 {
    width: 60%;
}
.Prescription_wrapInp__2X0D8 input,
.Prescription_wrapInp__2X0D8 textarea {
    width: 100%;
}

.Prescription_Prescription__2hVzX form input {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Prescription_Prescription__2hVzX form input::-webkit-input-placeholder {
    color: var(--grey2);
}
.Prescription_Prescription__2hVzX form input:-ms-input-placeholder {
    color: var(--grey2);
}
.Prescription_Prescription__2hVzX form input::placeholder {
    color: var(--grey2);
}
.Prescription_Prescription__2hVzX form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Prescription_Prescription__2hVzX form button:hover {
    background: var(--primary);
}

.Prescription_Prescription__2hVzX label {
    color: var(--dark-blue);
    font-size: 14px;
}
.Prescription_Prescription__2hVzX form textarea {
    border: none;
    border-radius: 4px;
    background: var(--light-grey);
    color: var(--grey);
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 20px;
}
.Prescription_Prescription__2hVzX form textarea:hover,
.Prescription_Prescription__2hVzX form textarea:focus {
    color: var(--grey);
    outline: 0;
}

.Professional_Professional__3WY15 {
    padding: 0 20px 20px 50px;
}
.Professional_Professional__3WY15 h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}

.Professional_successFlash__2tsRE {
    color: var(--white);
    background: var(--green);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.Professional_warningFlash__31V4N {
    color: var(--white);
    background: var(--red);
    padding: 10px;
    font-size: 12px;
    border-radius: 2px;
    margin-bottom: 20px;
}
.Professional_wrapInp__2_urc {
    display: grid;
    grid-template-columns: minmax(0, 60%) minmax(0, 40%);
    grid-gap: 10px;
    gap: 10px;
}
.Professional_wrapInp__2_urc input,
.Professional_wrapInp__2_urc textarea {
    width: 100%;
}

.Professional_Professional__3WY15 form input {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Professional_Professional__3WY15 form input::-webkit-input-placeholder {
    color: var(--grey2);
}
.Professional_Professional__3WY15 form input:-ms-input-placeholder {
    color: var(--grey2);
}
.Professional_Professional__3WY15 form input::placeholder {
    color: var(--grey2);
}
.Professional_Professional__3WY15 form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Professional_Professional__3WY15 form button:hover {
    background: var(--primary);
}

.Settings_Wrapper__S2_BS {
    background: var(--light);
    max-width: 100vw;
    min-height: 100vh;
    padding: 20px 20px 20px 75px;
}

.Settings_Settings__2WEZf {
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 5px 10px var(--light-grey);
    display: grid;
    grid-template-columns: minmax(0, 25%) minmax(0, 75%);
}

.SettingsNav_SettingsNav__KHoim {
    padding-bottom: 10px;
}
.SettingsNav_Wrap__2H-qM {
    border-right: 1px solid var(--light-grey);
}
.SettingsNav_SettingsNav__KHoim h3 {
    color: var(--primary);
    font-weight: 500;
    margin: 15px 0 5px 20px;
}
.SettingsNav_SettingsNav__KHoim p {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 5px 0 4px 20px;
    cursor: pointer;
    position: relative;
}
.SettingsNav_SettingsNav__KHoim p::before {
    position: absolute;
    content: '';
    right: -10px;
    top: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-top: 10px solid var(--light-grey);
    border-right: 10px solid var(--light-grey);
    border-bottom: 10px solid var(--light-grey);
    border-left: 10px solid var(--light-grey);
    display: none;
}

.SettingsNav_SettingsNav__KHoim p:hover {
    /* background: var(--primary); */
    color: var(--primary);
}
/* .SettingsNav p:hover::before {
    display: block;
} */

.SettingsNav_SettingsNav__KHoim p.SettingsNav_active__503_x {
    background: var(--light-grey);
    color: var(--primary);
}
.SettingsNav_SettingsNav__KHoim p.SettingsNav_active__503_x::before {
    display: block;
}

.Speciality_Speciality__3-NWI {
    padding: 0 20px 20px 50px;
}

.Speciality_Speciality__3-NWI h4 {
    color: var(--primary);
    font-weight: 500;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    border-bottom: 2px solid var(--primary);
}

.Speciality_wrapInp__3ZzQu {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-gap: 5px;
    gap: 5px;
    width: 50%;
}
.Speciality_Speciality__3-NWI form input {
    display: block;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    background: var(--light-grey);
    border-radius: 4px;
    padding: 8px;
    font-size: 12px;
    color: var(--grey);
    box-sizing: border-box;
}
.Speciality_Speciality__3-NWI form button {
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    display: inline-block;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    transition: 200ms;
}
.Speciality_Speciality__3-NWI form button:hover {
    background: var(--primary);
}

.Speciality_RegisteredSpeciality__1oTQa {
    margin-top: 30px;
    width: 50%;
}
.Speciality_RegisteredSpeciality__1oTQa h5 {
    margin: 0 0 5px 0;
    font-weight: 400;
    color: var(--primary);
}
.Speciality_RegisteredSpeciality__1oTQa p {
    display: inline;
    font-size: 11px;
    background: var(--light-grey);
    color: var(--grey);
    border-radius: 2px;
    padding: 2px 4px;
    margin-right: 4px;
}
.Speciality_RegisteredSpeciality__1oTQa p span {
    margin: 0 5px;
    color: var(--dark-blue);
    cursor: pointer;
}

:root {
    --primary: #457dc3;
    --secondary: #4885cf;
    --dark: #333;
    --dark-blue: #23395d;
    --dark-grey: #555;
    --grey: #7987a1;
    --grey2: #aebacf;
    --light-grey: #dee2e9;
    --light: #f0f0f0;
    --white: #fff;
    --green: #5cb484;
    --red: #ef5455;
}
* {
    font-family: Poppins;
}
body {
    margin: 0;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

