.Forum {
    background: var(--white);
    padding: 5px;
    box-sizing: border-box;
    margin: 20px 20px 20px 0;
}
.Forum h2 {
    color: var(--primary);
    margin: 5px 25px;
    font-weight: 400;
    font-size: 24px;
}
.Forum > div {
    margin: 20px 20px;
    border-bottom: 2px solid var(--light);
    transition: 200ms;
}
.Forum > div:hover {
    transform: scale(1.02);
    cursor: pointer;
}
.Forum > div > h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px;
    display: inline;
}
.Forum > div > p:nth-child(2) {
    display: inline;
    font-size: 10px;
    margin: 0 10px;
    color: var(--dark-grey);
}
.Forum > div > p:nth-child(3) {
    font-size: 12px;
    font-weight: 500;
    margin: 5px;
    color: var(--secondary);
}
.Forum > div > p:nth-child(4) {
    font-size: 12px;
    margin: 5px;
    color: var(--deep-blue);
}
