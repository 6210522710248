@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root {
    --primary: #457dc3;
    --secondary: #4885cf;
    --dark: #333;
    --dark-blue: #23395d;
    --dark-grey: #555;
    --grey: #7987a1;
    --grey2: #aebacf;
    --light-grey: #dee2e9;
    --light: #f0f0f0;
    --white: #fff;
    --green: #5cb484;
    --red: #ef5455;
}
* {
    font-family: Poppins;
}
body {
    margin: 0;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
