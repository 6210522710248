.SinglePatient {
    padding: 10px 0 0 10px;
    overflow: hidden;
}
.SinglePatient > p {
    margin: 4px 0;
}
.SinglePatient > p:nth-child(2) {
    font-size: 12px;
    color: var(--dark-blue);
}
.SinglePatient > p:nth-child(3) {
    font-size: 10px;
    color: var(--grey);
}
.SinglePatient > p:nth-child(3) > span:nth-child(2),
.SinglePatient > p:nth-child(3) > span:nth-child(3) {
    margin-left: 20px;
}
