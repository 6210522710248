.SingleDoctor {
    padding: 10px 0 0 10px;
    overflow: hidden;
}
.SingleDoctor > p {
    margin: 4px 0;
}

.SingleDoctor p:nth-child(2) {
    font-size: 14px;
    margin-top: 15px;
    color: var(--primary);
    font-weight: 500;
}
.SingleDoctor p:nth-child(3) {
    font-size: 12px;
    margin-top: 4px;
    color: var(--dark-blue);
    font-weight: 500;
}

.SingleDoctor p:nth-child(4) {
    font-size: 12px;
    margin-top: 4px;
    color: var(--grey);
    font-weight: 500;
}
