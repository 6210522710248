.Professional {
    background: var(--white);
    border-radius: 4px;
    padding: 20px;
    margin: 20px 0 0 20px;
    box-shadow: 0 5px 10px var(--light-grey);
}

.Professional h4 {
    margin: 5px 0;
}
.SingleProfession p {
    font-size: 12px;
    margin: 5px 0;
}
